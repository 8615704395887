import React from 'react'
import Layout from '../../components/Layout'
import Head from '../../components/Head'
import SEOWebpage from '../../components/SEO/webpage'
import ServicesList from '../../components/ServicesList/index'
import { useStaticQuery, graphql } from "gatsby"

export default function ServicePage() {

  const pageData = useStaticQuery(graphql`
    query ServicePage {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
      graphCmsServicePage {
        id
        pageHeaderSeo {
          metaDescription
          metaTitle
        }
        slug
        content {
          html
        }
      }
      
      allGraphCmsServicePosts {
        edges {
          node {
            title
            slug
            articleImage {
              gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
            }
            metaDescription
          }
        }
      }
    }
  `)

  const pageUrl = pageData.site.siteMetadata.siteUrl + "/" + pageData.graphCmsServicePage.slug + "/";
  const services = pageData.allGraphCmsServicePosts.edges;

  return (
    <Layout>
      <Head
        title={pageData.graphCmsServicePage.pageHeaderSeo.metaTitle}
        pageDesc={pageData.graphCmsServicePage.pageHeaderSeo.metaDescription}
        url={pageUrl}
      />
      <SEOWebpage
        title={pageData.graphCmsServicePage.pageHeaderSeo.metaTitle}
        meta_description={pageData.graphCmsServicePage.pageHeaderSeo.metaDescription}
        url={pageUrl}
      />
      <div className="pageSection">
        <div dangerouslySetInnerHTML={{ __html: pageData.graphCmsServicePage.content.html }} ></div>
        <ServicesList list={services}  />
      </div>

    </Layout>
  )
}
